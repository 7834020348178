html,
body {
    height: 100%;
}

.flow {
    --flow-space: var(--baseline-space);
}

.flow > * + * {
    margin-top: var(--flow-space);
}

section {
    margin: 0 1rem;

    &:nth-child(2n) {
        background-color: var(--color-background);
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
    padding: 4em 1em;
    position: relative;

    + .container {
        padding-top: 0;
    }
}

.column-2 {
    max-width: 1080px;

    > .container {
        width: 100%;
    }
}

.column-3 {
    max-width: 1200px;
    width: 1200px;

     > .container {
         padding-left: .5em;
         padding-right: .5em;
         width: calc(100% / 3);
     }
}

.flex {
    display: flex;
}

.flex-center {
    justify-content: center;
}

.flex-space-between {
    justify-content: space-between;
}

@media only screen and (min-width: 800px) {
    section {
        // max-width: 1000px;
        margin: 0;
    }

    .column-2 > .container {
        width: 50%;
    }

    .column-2 {
        display: flex;
    }

    .container + .container {
        padding-top: 4em;
    }
}
