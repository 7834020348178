@import '~modularscale-sass/stylesheets/modularscale';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$modularscale: (
        base: 1rem,
        ratio: $fourth
);

:root {
    --font-primary: Roboto;
    --font-secondary: sans-serif;

    --font-base-size: 1rem;
    --font-sm: #{ms(-1)};
    --font-md: #{ms(0)};
    --font-lg: #{ms(1)};
    --font-xl: #{ms(2)};
    --font-xxl: #{ms(3)};
    --font-xxxl: #{ms(4)};
    --font-xxxxl: #{ms(5)};
}

html {
    font-size: 16px;
}

body {
    font-family: var(--font-primary), var(--font-secondary);
    font-size: var(--font-base-size);
    font-weight: 400;
    line-height: var(--baseline-space);
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
    line-height: 1em;
    margin: 0;
    margin-bottom: 1em;
}

h1 {
    font-size: var(--font-xxxxl);
}

h2 {
    font-size: var(--font-xxxl);
}

h3 {
    font-size: var(--font-xxl);
}

h4 {
    font-size: var(--font-xl);
}

h5 {
    font-size: var(--font-lg);
}

h6 {
    font-size: var(--font-md);
}

p {
    line-height: var(--space-md);
    margin-bottom: 1em;
}

.mt-caption {
    color: var(--color-grey);
    font-size: var(--font-md);
    margin-left: 1em;
}

ul {
    line-height: var(--space-md);
    margin-bottom: 1em;
    padding-inline-start: 2em;
}

li {
    position: relative;

    &::before {
        @extend .mt-icon;
        @extend .mt-icon__music-note;

        color: var(--color-grey);
        position: absolute;
        left: -2em;
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}