@font-face {
    font-family: 'Icons';
    src: url('/assets/fonts/icomoon.eot?v=4.7.0');
    src: url('/assets/fonts/icomoon.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('/assets/fonts/icomoon.woff?v=4.7.0') format('woff'), url('/assets/fonts/icomoon.ttf?v=4.7.0') format('truetype'), url('/assets/fonts/icomoon.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.mt-icon::before {
    display: inline-block;
    font: normal normal normal 14px/1 Icons;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mt-icon__twitter::before {
    content: '\e900';
}

.mt-icon__facebook::before {
    content: '\e901';
}

.mt-icon__music-note::before {
    content: '\e902';
}

.mt-icon__blockquote::before {
    content: '\e907';
}

.mt-icon__instagram::before {
    content: '\e908';
}
