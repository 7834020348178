a {
    color: var(--color-primary);
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: color .2s;

    &:hover {
        color: var(--color-primary-light);
    }

    &:active {
        color: var(--color-primary-dark);
    }
}
