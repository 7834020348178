$color-primary: #66c3d2;

:root {
    --color-primary: #{$color-primary};
    --color-primary-light: #{lighten($color-primary, 10%)};
    --color-primary-lightest: #f4fdfe;
    --color-primary-dark: #{darken($color-primary, 20%)};

    --color-grey: grey;

    --color-text: #373d3f;
    --color-background: var(--color-primary-lightest);
}
